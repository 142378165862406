import React from 'react';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

const PredictionList = ({predictions}) => {
    const modelsDev = Math.round((predictions[2] - predictions[3])*1000)/1000;
    return (
        <>
        <h2 className="mt-2 mb-3 display-font">Explainable Model Prediction</h2>
        <ListGroup horizontal={'md'} className="my-2 container">
            <ListGroup.Item><strong>Sum of feature weights:</strong> {predictions[0]}</ListGroup.Item>
            <OverlayTrigger
            key="over-local"
            placement="bottom"
            overlay={
                <Tooltip id="tip-local">
                    <span>Prediction of explainable model</span>
                </Tooltip>
            }
            >
                <ListGroup.Item>Local prediction: {predictions[2]}</ListGroup.Item>
            </OverlayTrigger>  
            <OverlayTrigger
            key="over-interc"
            placement="bottom"
            overlay={
                <Tooltip id="tip-interc">
                    <span>Intercept of explainable model</span>
                </Tooltip>
            }
            >
                <ListGroup.Item>Intercept: {predictions[1]}</ListGroup.Item>
            </OverlayTrigger>
            <OverlayTrigger
            key="over-ml"
            placement="bottom"
            overlay={
                <Tooltip id="tip-ml">
                    <span>Prediction of ML model</span>
                </Tooltip>
            }
            >
                <ListGroup.Item>Model Prediction: {predictions[3]}</ListGroup.Item>
            </OverlayTrigger>
            <ListGroup.Item>Explainable - ML Models Deviation: {modelsDev}</ListGroup.Item>
        </ListGroup>
        </>
    );
}

export default PredictionList;