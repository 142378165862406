import React from "react";
import "./CategoryTabs.css";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CategoryTabs = ({featureCategories, setActiveCategory, activeCategory, basicCat}) => {
    const categoryKeys = Object.keys(featureCategories[0]);

    const handleSelect = (key) => {
        setActiveCategory(key);
    }

    // features values sum per category calculation
    const categoryValues = Object.values(featureCategories[1]);
    let sumsArr = [];

    for (let j=0; j < categoryValues.length; j++) {
        let catSum = 0;
        for (let k=0; k < categoryValues[j].length; k++) {
            catSum += Math.abs(categoryValues[j][k][1]);
        }
        sumsArr.push(catSum);
    }

    const sumsCat = new Map([
        ['content', sumsArr[0]],
        ['network', sumsArr[1]],
        ['sentiment', sumsArr[2]],
        ['temporal', sumsArr[3]],
        ['user', sumsArr[4]]
    ]);
    const sumsCatObj = Object.fromEntries(sumsCat);

    return (
        <>
            <hr />
            <span id="green-dot"></span> These features contribute positively to identifying the user as human
            <br />
            <span id="red-dot"></span> These features push the Machine Learning model to identifying the user as bot
            <hr />

            <Tabs defaultActiveKey={basicCat === "" ? "content" : activeCategory } className="mb-3" onSelect={handleSelect}>
                {categoryKeys.map((v, i) => 
                    <Tab className="tab-titles" key={i} title={v.charAt(0).toUpperCase() + v.slice(1)} eventKey={v}></Tab>
                )}
            </Tabs>

            {Object.values(featureCategories[1][activeCategory]).map((v, i) =>              
                <div key={i} className={v[1] > 0 ? "category-tab-card red-bg-tab" : " category-tab-card green-bg-tab"}>
                    <Row className="align-items-center">
                        <Col xs={12} md={10}>
                            {/* <p>percentage: {(Math.round(Math.abs(v[1]) / sumsCatObj[activeCategory] * 10000) / 100)}%</p> */}
                            <h5>{featureCategories[2][v[2]].charAt(0).toUpperCase() + featureCategories[2][v[2]].slice(1)}</h5>
                            <span>{v[0].replace(v[2], "feature value")}</span>
                        </Col>
                        <Col xs={12} md={2}>
                            <CircularProgressbar 
                                value={Math.round(Math.abs(v[1]) / sumsCatObj[activeCategory] * 10000) / 10000} 
                                maxValue={1} 
                                text={`${(Math.round(Math.abs(v[1]) / sumsCatObj[activeCategory] * 10000) / 100)}%`} 
                                background
                                backgroundPadding={6}
                                styles={buildStyles({
                                    backgroundColor: "transparent",
                                    textColor: "#343a40",
                                    // pathColor: "rgba(0,0,0,0.3)",
                                    pathColor: v[1] > 0 ? "rgba(242,100,68,0.5)" : "rgba(4,217,196,0.5)",
                                    trailColor: v[1] > 0 ? "rgba(242,100,68,0.1)" : "rgba(4,217,196,0.1)"
                                  })}
                                  className="card-progress"
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </>
    )
}

export default CategoryTabs;
