import React from 'react';
import "./Footer.css";
import logo from "../../assets/images/logo.png";
import { Alert, Row, Col, Container } from "react-bootstrap";
import { animateScroll } from "react-scroll";

const Footer = () => {
    const [hideToTopButton, setToTopButton] = React.useState(true);

    // Scroll to top
    const scrollToTop = () => {
        animateScroll.scrollToTop({
            duration: 500,
            smooth: true,
        });
    }

    // Show/hide toTopButton
    window.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
            setToTopButton(false);
        } else {
            setToTopButton(true);
        }
    });
    
    return (
        <section id="footer">
            <Container fluid className="mx-0 w-100">
                <Row className="justify-content-around align-items-center text-center py-5">
                    <Col md={2} sm={12} className="pb-3 pb-md-0 pb-lg-0">
                        <Alert.Link href="/"><img src={logo} alt="Bot Detective" /></Alert.Link>
                    </Col>
                    <Col md={6} sm={12} className="b-3 pb-md-0 pb-lg-0">
                        <ul className="p-0 m-md-0 m-lg-0">
                            <li><Alert.Link href="/faq">FAQ</Alert.Link></li>
                            <li>|</li>
                            <li><Alert.Link href="/publications">Publications</Alert.Link></li>
                            <li>|</li>
                            <li className="p-0"><Alert.Link href="/datasets">Bot Datasets</Alert.Link></li>
                            {/* <li>|</li>
                            <li><Alert.Link href="#">Privacy Policy</Alert.Link></li>
                            <li>|</li>
                            <li><Alert.Link href="#">Terms of Use</Alert.Link></li> */}
                        </ul>
                    </Col>
                    <Col md={4} sm={12}>
                        <span>&copy; <Alert.Link target="_blank" href="https://datalab.csd.auth.gr/">Datalab</Alert.Link>, Aristotle Uni. of Thessaloniki</span>
                    </Col>
                </Row>
            </Container>
            <Alert.Link href="#" id="toTopBtn" className={hideToTopButton ? "topTopBtnHidden" : "toTopBtn cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out"} data-abc="true" onClick={scrollToTop}></Alert.Link> </section>
    );
}

export default Footer;