import React from "react";
import "./FeedbackModal.css";
import useFetch from "use-http";

import { Button, Form, Modal } from "react-bootstrap";

const FeedbackModal = ({show, setShow, username, botname}) => {
    const [request] = useFetch(process.env.REACT_APP_SERVER_URL, {
        timeout: 600000,
        cachePolicy: "no-cache"
    });
    
    const handleClose = () => setShow(false);

    const accountTypes = {
        "cyborg": "Cyborg",
        "political_bot": "Political Bot",
        "human": "Human",
        "self_declared_bot": "Self Declared Bot",
        "social_bot": "Social Bot",
        "spam_bot": "Spam Bot",
        "other_bot": "Other Bot"
    }

    const optionOneRadios = [
        { name: "Agree", value: "agree" },
        { name: "Disagree", value: "disagree" }
    ];

    const optionTwoRadios = [
        { name: "Human (a genuine human account)", value: "human" },
        { name: "Cyborg (a celebrity or organization-company that uses automation)", value: "cyborg" },
        { name: "Social Bot (a bot account that imitates a human, trying to get likes, increase its network of friends and followers, etc.)", value: "social_bot" },
        { name: "Political Bot (a non-verified bot account that is being used for political purposes, affecting public opinion and elections)", value: "political_bot" },
        { name: "Spam Bot (a bot account that posts spam messages - usually spam content, frauds, etc.)", value: "spam_bot" },
        { name: "Self-Declared Bot (a Twitter account that explicitly claims to be a bot)", value: "self_declared_bot" },
        { name: "Other bot (bot for sure, but i don't know what type of bot)", value: "other_bot" }
    ];

    const optionFourRadios = [
        { name: "Strongly Disagree", value: "strongly_disagree" },
        { name: "Disagree", value: "disagree" },
        { name: "Neutral", value: "neutral" },
        { name: "Agree", value: "agree" },
        { name: "Strongly Agree", value: "strongly_agree" }
    ];

    const [optionOneValue, setOptionOneValue] = React.useState("agree");
    const [optionTwoValue, setOptionTwoValue] = React.useState(null);
    const [optionThreeValue, setOptionThreeValue] = React.useState(null);
    const [optionFourValue, setOptionFourValue] = React.useState(null);
    const [optionFiveValue, setOptionFiveValue] = React.useState(null);
    const [optionSixValue, setOptionSixValue] = React.useState("");
    
    const handleTextArea = (e) => {
        setOptionSixValue(e.target.value);
    }

    const [buttonEnabled, setButtonEnabled] = React.useState(null);
    const [feedbackText, setFeedbackText] = React.useState(null);

    React.useEffect(() => {
        if (optionOneValue === "agree") setOptionTwoValue(null);

        const validateFeedback = () => {
            if (optionOneValue === "disagree") return optionOneValue && optionTwoValue && optionThreeValue != null ? false : true
            else return optionOneValue && optionThreeValue != null ? false : true
        }

        setButtonEnabled(validateFeedback());
    }, [
        optionOneValue,
        optionTwoValue,
        optionThreeValue,
        optionFourValue,
        optionFiveValue,
        optionSixValue,
    ])

    const submitFeedback = async () => {
        const feedback = {
            username: username.startsWith("@") ? username.slice(1) : username,
            optionOne: optionOneValue,
            optionTwo: optionTwoValue,
            optionThree: optionThreeValue,
            optionFour: optionFourValue,
            optionFive: optionFiveValue,
            optionSix: optionSixValue
        }

        setOptionOneValue("agree");
        setOptionTwoValue(null);
        setOptionThreeValue(null);
        setOptionFourValue(null);
        setOptionFiveValue(null);
        setOptionSixValue("");

        const respData = await request.post("/feedback", feedback);

        if (respData.ok) {
            setFeedbackText("Thanks for your feedback!");
            setTimeout(() => {
                handleClose();
                setFeedbackText("");
            }, 1500);
        } else {
            setFeedbackText("Submit failed");
        }
    }

    return (
        <Modal className="feedback-modal" show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                    <p><b>I agree with the prediction that user { username } is a { accountTypes[botname] } (required):</b></p>
                    {optionOneRadios.map((value, index) => (
                        <div key={index} className="mb-3">
                            <Form.Check
                                type="radio"
                                id={`${value.name}-${index}-1`}
                                label={value.name}
                                onClick={() => setOptionOneValue(value.value)}
                                name="groupOptionOne"
                            />
                        </div>
                    ))}
                    {optionTwoRadios.map((value, index) => {
                    if(optionOneValue === "disagree") {
                        if(index === 0) {
                            return (
                                <div key={index}>
                                    <p><b>I believe the account { username } is a <span className="text-primary">{ optionTwoValue ? accountTypes[optionTwoValue] : "..." }</span> (required):</b></p>
                                    <div className="mb-3">
                                        <Form.Check
                                        type="radio"
                                        label={value.name}
                                        id={`${value.name}-${index}-2`}
                                        onClick={() => setOptionTwoValue(value.value)}
                                        name="groupOptionTwo"
                                        />
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} className="mb-3">
                                    <Form.Check
                                        type="radio"
                                        label={value.name}
                                        id={`${value.name}-${index}-2`}
                                        onClick={() => setOptionTwoValue(value.value)}
                                        name="groupOptionTwo"
                                    />
                                </div>
                            )
                        }
                    }
                    return null
                    })}
                    <p><b>I believe the account { username } is spreading fake news (required):</b></p>
                    {optionOneRadios.map((value, index) => (
                        <div key={index} className="mb-3">
                            <Form.Check
                                type="radio"
                                label={value.name}
                                id={`${value.name}-${index}-3`}
                                onClick={() => setOptionThreeValue(value.value)}
                                name="groupOptionThree"
                            />
                        </div>
                    ))}

                    <p><b>The application helped me understand if the account is a bot or not (optional):</b></p>
                    {optionFourRadios.map((value, index) => (
                        <div key={index} className="mb-3">
                            <Form.Check
                                type="radio"
                                label={value.name}
                                id={`${value.name}-${index}-4`}
                                onClick={() => setOptionFourValue(value.value)}
                                name="groupOptionFour"
                            />
                        </div>
                    ))}

                    <p><b>I believe that the Bot-Detective application is easy to use (optional):</b></p>
                    {optionFourRadios.map((value, index) => (
                        <div key={index} className="mb-3">
                            <Form.Check
                                type="radio"
                                label={value.name}
                                id={`${value.name}-${index}-5`}
                                onClick={() => setOptionFiveValue(value.value)}
                                name="groupOptionFive"
                            />
                        </div>
                    ))}

                    <p><b>Help us get better - provide your suggestions (optional):</b></p>
                    <Form.Control as="textarea" rows={3} onInput={handleTextArea} placeholder="Up to 140 characters" maxLength={140} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <p>{ feedbackText }</p>
                <Button variant="secondary" className="round-btn" onClick={handleClose}>Close</Button>
                <Button variant="primary" className="round-btn" disabled={buttonEnabled} onClick={submitFeedback}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default FeedbackModal;