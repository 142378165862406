import React from 'react';
import './Header.css';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';

const header = (props) => {
  return (
    <div className="App">
    <>
        <section>
            <Container fluid id="header-in" className="w-100">
                <Container>
                    <Row id="header-in-title" className="m-auto">
                        <Col xs={12} className="pl-0">
                            <h1 className="display-font">{props.name}</h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    </>
    </div>
  );
}


export default header;