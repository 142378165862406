import React from 'react';
import Container from 'react-bootstrap/Container';
import { Alert } from 'react-bootstrap';
import Header from '../components/Header/Header';
import AppHeader from '../components/AppHeader/AppHeader';
import Footer from '../components/Footer/Footer';

const publications = () => {
	return (
		<div className="App">
			<AppHeader />
				<>
					<Header name="Publications" />
					<section id="mainpage-publications">
					 <Container className="pt-5 pb-2">
					  <Alert.Link href="https://dl.acm.org/doi/abs/10.1145/3415958.3433075" target="_blank"><h4>Bot-detective: An explainable Twitter bot detection service with crowdsourcing functionalities</h4></Alert.Link>
					  <p>Maria Kouvela, Ilias Dimitriadis, Athena Vakali</p>
					  <hr />
					 </Container>
					 <Container className="pt-2 pb-2">
					  <Alert.Link href="https://ieeexplore.ieee.org/document/8421373" target="_blank"><h4>TRIAGE: Temporal Twitter attribute graph patterns</h4></Alert.Link>
					  <p>Ilias Dimitriadis, Marinos Poiitis, Christos Faloutsos, Athena Vakali</p>
					  <hr />
					 </Container>
					 <Container className="pt-2 pb-2">
					  <Alert.Link href="https://www.mdpi.com/2076-3417/11/21/9857" target="_blank"><h4>Social Botomics: A Systematic Ensemble ML Approach for Explainable and Multi-Class Bot Detection </h4></Alert.Link>
					  <p>Ilias Dimitriadis, Konstantinos Georgiou, Athena Vakali</p>
					  <hr />
					 </Container>
					</section>
				</>
			<Footer />
		</div>
	);
}

export default publications;