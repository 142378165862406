import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import localforage from "localforage";
import useFetch from "use-http";
import logo from "../../assets/images/logo.png";
import Navbar from "react-bootstrap/Navbar";
import { Nav, NavItem } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaTwitter } from "react-icons/fa";
import { animateScroll } from "react-scroll";

import "./AppHeader.css";

import { useGlobalState } from "../../globalContext";

const AppHeader = () => {
	const [request] = useFetch(process.env.REACT_APP_SERVER_URL, { timeout: 600000 });
    const [navScrolled, setNavScrolled] = React.useState(true);
	const [state, updateState] = useGlobalState();

	React.useEffect(() => {
		let didCancel = false;
		localforage.getItem("user_info").then((info) => {
			if (!didCancel) updateState({ type: "changeUserInfo", userInfo: info });
		});
		return () => didCancel = true;
	});

	const loginWithTwitter = () => {
		request.get("/req4req")
			.then(({ oauth_token, oauth_token_secret } = {}) => {
				localforage.setItem("oauth_token_secret", oauth_token_secret)
					.then(() => {
						if (oauth_token) {
							window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
						} else {
							throw new Error("oauth_token is undefined!");
						}
					})
			.catch(console.error);
		});
	};

	// Scroll to top
    const scrollToTop = () => {
        animateScroll.scrollToTop({
            duration: 500,
            smooth: true,
        });
    }

	// Show/hide toTopButton
    window.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
            setNavScrolled(false);
        } else {
            setNavScrolled(true);
        }
    });

	return (
		<div className="App">
			<>
				<section id="navigation">
					<Navbar id="mainNavbar" expand="lg" fixed="top" className={navScrolled ? "px-5" : "px-5 scrolled"}>
						<Navbar.Brand href="/"><img src={logo} className="img-fluid" alt="Bot Detective" /></Navbar.Brand>
						<Navbar.Toggle className="anim-button navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<div className="animated-icon"><span></span><span></span><span></span></div>
						</Navbar.Toggle>
						<Navbar.Collapse className="justify-content-end" id="navbarSupportedContent">
						<Nav className="justify-content-end">
						<NavItem><Nav.Link href="/">Home</Nav.Link></NavItem>
						<NavItem><Nav.Link href="/faq">FAQ</Nav.Link></NavItem>
						<NavItem><Nav.Link href="/publications">Publications</Nav.Link></NavItem>
						<NavItem><Nav.Link href="/datasets">Bot Datasets</Nav.Link></NavItem>
							<Button 
								active
								name="login"
								onClick={loginWithTwitter}
								variant="outline-light" 
								size="lg" 
								className="header-btn ml-lg-4 my-2 my-lg-0"
								style={{...(state.userInfo ? { display: "none" } : {}) }}
							><FaTwitter /> Login</Button>
							<Button 
								active
								name="logout"
								onClick={() => {
									localforage.clear()
										.then(() => {
											updateState({ type: "changeUserInfo", userInfo: null });
											window.location.reload();
											scrollToTop();
										})
										.catch(console.error);
									}}
								variant="outline-light" 
								size="lg" 
								className="header-btn ml-lg-4 my-2 my-lg-0 zindex-dropdown"
								style={{...(!state.userInfo ? { display: "none" } : {}) }}
							>Logout</Button>
						</Nav>
					</Navbar.Collapse>
					</Navbar>
				</section>
			</>
      	</div>
	);
};

AppHeader.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withRouter(AppHeader);
