/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { Container, Spinner, Row, Col } from "react-bootstrap";
import useFetch from "use-http";
import queryString from "query-string";
import localforage from "localforage";
import Header from "../components/Header/Header";
import AppHeader from '../components/AppHeader/AppHeader';
import Footer from "../components/Footer/Footer";

import Plotly from "plotly.js/lib/core";
import createPlotlyComponent from "react-plotly.js/factory";

import { useGlobalState } from "../globalContext";
import get from "../get";

Plotly.register([require("plotly.js/lib/bar")]);

const Plot = createPlotlyComponent(Plotly);

const Details = (props) => {
	const { match: { params: { username } } } = props;
	const [request, response] = useFetch(process.env.REACT_APP_SERVER_URL, { timeout: 600000 });
	const [state, updateState] = useGlobalState();

	React.useEffect(() => {
		let didCancel = false;
		localforage.getItem("user_info").then((info) => {
			if (!didCancel) updateState({ type: "changeUserInfo", userInfo: info });
		});
		return () => didCancel = true;
	}, []);

	React.useEffect(() => {
		if (state.userInfo && !response.data) request.get(`/details/${username.startsWith("@") ? username.slice(1) : username}?${queryString.stringify(state.userInfo)}`);
	}, [state.userInfo, username]);

	if (request.loading) {
		return (
			<Container className="loader zindex-tooltip">
				<Spinner animation="border" role="status">
  					<span className="sr-only">Loading...</span>
				</Spinner>
			</Container>
		);
	}

	return (
		<div className="App">
			<AppHeader />
			<>
			<Header name="Details" />
			<Container className="py-2 py-md-5">
				<div className="details-wrapper">
					<Row className="details-row">
						<Col className="mt-2 details-image" md={1}>
							<img className="details-avatar h-100 rounded-circle" src={get(response.data, "image")} alt="" />
						</Col>
						<Col>
							<h2 className="details-username text-center text-md-left px-md-3 py-md-3">{`@${username}`}</h2>
						</Col>
					</Row>
					<hr/>
					<Row className="py-3">
						<Col md={6} sm={12}>
							{get(response.data, "screen_name") && (
								<h5>Screen name: <span className="details-span">{get(response.data, "screen_name")}</span></h5>
							)}
							{get(response.data, "display_name") && (
								<h5>Display name: <span className="details-span">{get(response.data, "display_name")}</span></h5>
							)}
							{get(response.data, "twitter_user_id") && (
								<h5>Twitter user ID: <span className="details-span">{get(response.data, "twitter_user_id")}</span></h5>
							)}
							{get(response.data, "location") && (
								<h5>Location: <span className="details-span">{get(response.data, "location")}</span></h5>
							)}
							{get(response.data, "url") && (
								<h5>URL: <a href={get(response.data, "url")} target="_blank" rel="noreferrer" className="details-url">{get(response.data, "url")}</a></h5>
							)}
							{get(response.data, "date_joined") && (
								<h5>Date joined: <span className="details-span">{get(response.data, "date_joined")}</span></h5>
							)}
							{get(response.data, "most_recent_post") && (
								<h5>Most recent post: <span className="details-span">{get(response.data, "most_recent_post")}</span></h5>
							)}
							{get(response.data, "tweets") && (
								<h5>Tweets: <span className="details-span">{get(response.data, "tweets")}</span></h5>
							)}
						</Col>
						<Col md={6} sm={12} className="py-3 py-md-0">
							<h5>Following: <span className="details-span">{get(response.data, "following")}</span></h5>
							<h5>Followers: <span className="details-span">{get(response.data, "followers")}</span></h5>
							<h5>Likes: <span className="details-span">{get(response.data, "likes")}</span></h5>
							<h5>Lists: <span className="details-span">{get(response.data, "lists")}</span></h5>
							<h5>Tweet language: <span className="details-span">{get(response.data, "tweet_language")}</span></h5>
							<h5>Tweets this week: <span className="details-span">{get(response.data, "tweets_this_week")}</span></h5>
							<h5>Retweet ratio: <span className="details-span">{`${get(response.data, "retweet_ratio", 0)}%`}</span></h5>
						</Col>
						{get(response.data, "description") && (
							<h5 className="details-description">Description: <hr/><p>{get(response.data, "description")}</p></h5>
						)}
					</Row>
				</div>
				<Row className="mt-5">
					<Col md={6} sm={12} className="py-3 py-md-0" >
						<h3>Tweets by day of week</h3>
						<Plot
							data={[
								{
									x: [
										"Mon",
										"Tues",
										"Wed",
										"Thurs",
										"Fri",
										"Sat",
										"Sun",
									],
									y: get(response.data, "tweets_by_day_of_week", []),
									type: "bar",
									mode: "lines",
								},
							]}
							layout={{
								showlegend: false,
								autosize: true,
								xaxis: {
									autorange: true,
									title: "Day",
									showgrid: true,
									zeroline: false,
								},
								yaxis: {
									title: "Tweets",
									showgrid: true,
									zeroline: false,
								},
								margin: { t: 10, l: 40, r: 30, b: 70 },
							}}
							style={{ width: "100%", height: "24rem" }}
							config={{ displayModeBar: false, responsive: true }}
							useResizeHandler
						/>
					</Col>
					<Col md={6} sm={12} className="py-3 py-md-0" >
						<h3>Tweets by hour of day</h3>
						<Plot
							data={[
								{
									x: [
										"12:00 am",
										"01:00 am",
										"02:00 am",
										"03:00 am",
										"04:00 am",
										"05:00 am",
										"06:00 am",
										"07:00 am",
										"08:00 am",
										"09:00 am",
										"10:00 am",
										"11:00 am",
										"12:00 pm",
										"01:00 pm",
										"02:00 pm",
										"03:00 pm",
										"04:00 pm",
										"05:00 pm",
										"06:00 pm",
										"07:00 pm",
										"08:00 pm",
										"09:00 pm",
										"10:00 pm",
										"11:00 pm",
									],
									y: get(response.data, "tweets_by_hour_of_day", []),
									type: "bar",
									mode: "lines",
								},
							]}
							layout={{
								showlegend: false,
								autosize: true,
								xaxis: {
									autorange: true,
									title: "Hour",
									showgrid: true,
									zeroline: false,
									nticks: 5,
								},
								yaxis: {
									title: "Tweets",
									showgrid: true,
									zeroline: false,
								},
								margin: { t: 10, l: 40, r: 30, b: 70 },
							}}
							style={{ width: "100%", height: "24rem" }}
							config={{ displayModeBar: false, responsive: true }}
							useResizeHandler
						/>
					</Col>
				</Row>
			</Container>
			</>
		<Footer />
		</div>
	);
};

Details.propTypes = { match: PropTypes.object.isRequired };
export default Details;
