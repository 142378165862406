import React from 'react';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Row, Col } from 'react-bootstrap';

const ProgressCircles = ({list}) => {
  const sortedList = list.sort((perc1, perc2) => perc2.progress - perc1.progress);
  
    return (
        <div className="container pt-4">
            <Row>
              {sortedList.map(({name, progress, image}) =>
                  <Col xs={6} md={4} lg={2} className="py-3" key={name}>
                    <CircularProgressbarWithChildren 
                    value={progress} 
                    styles={{
                      root: {},
                      path: {
                        stroke: `rgba(${100 - progress}, 45, 136, 1.0)`,
                      }
                    }}>
                    <img
                      className="results-img w-50"
                      src={image}
                      alt={name}
                    />
                    </CircularProgressbarWithChildren>
                    <div className="text-center mx-auto mt-3"><h5>{`${name}:`}</h5><h4>{`${progress}%`}</h4></div>
                </Col>
                )
              }
            </Row>
        </div>
    );
}

export default ProgressCircles;