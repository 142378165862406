/* eslint-disable max-len */
import "../App.css";
import React from "react";
import ReactDOM from "react-dom";
import useFetch from "use-http";
import localforage from "localforage";
import queryString from "query-string";
import { scroller } from "react-scroll";

import { Container, Button, Alert, Row, Col, Card, CardGroup, InputGroup, Form, ButtonGroup, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FaArrowLeft, FaArrowDown, FaQuestion, FaInfo, FaUsers, FaSearch, FaTwitter, FaUserAlt, FaChartBar, FaPen, FaDownload } from "react-icons/fa";

import { useGlobalState } from "../globalContext";
import useWindowSize from "../utils/useWindowSize";

import CategoryCards from "../components/CategoryCards/CategoryCards";
import CategoryTabs from "../components/CategoryTabs/CategoryTabs";
import PredictionList from "../components/PredictionList/PredictionList"
// import ReasonList from "../components/ReasonList/ReasonList";
import AppHeader from "../components/AppHeader/AppHeader";
import FeedbackModal from "../components/FeedbackModal/FeedbackModal";
import Footer from "../components/Footer/Footer";
import Loader from "../components/UI/Loader";
import ProgressCircles from "../components/ProgressCircles/ProgressCircles";

// Images
import cyborgImg from "../assets/images/cyborg.png";
import humanImg from "../assets/images/human_new.png";
import politicalBotImg from "../assets/images/political_bot.png";
import selfDeclaredBotImg from "../assets/images/self_declared_bot.png";
import socialBotImg from "../assets/images/social_bot.png";
import spamBotImg from "../assets/images/spam_bot.png";

const Home = () => {
  const { width } = useWindowSize();

  const [username, setUsername] = React.useState("");
  const [usernameTouched, setUsernameTouched] = React.useState(false);
  const [state, updateState] = useGlobalState();
  const [request] = useFetch(process.env.REACT_APP_SERVER_URL, {
    timeout: 600000,
  });

  const [show, setShow] =  React.useState(false);

  const handleShow = () => setShow(true);

  const [botname, setBotname] = React.useState(null);

  const [explanationType, setExplanationType] = React.useState("basic");
  const [predictions, setPredictions] = React.useState(null);
  const [reasons, setReasons] = React.useState(null);
  const [features, setFeatures] = React.useState(null);
  const [progressBars, setProgressBars]  = React.useState([]);
  const [activeCategory, setActiveCategory] = React.useState("content");
  const [basicCat, setBasicCat] = React.useState("");
  
  const [historyObject, setHistoryObject] = React.useState([]);

  React.useEffect(() => {
    let didCancel = false;
    localforage.getItem("user_info").then((info) => {
      if (!didCancel) updateState({ type: "changeUserInfo", userInfo: info });
    });
    return () => (didCancel = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithTwitter = () => {
    request.get("/req4req").then(({ oauth_token, oauth_token_secret } = {}) => {
      localforage
        .setItem("oauth_token_secret", oauth_token_secret)
        .then(() => {
          if (oauth_token) {
            window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
          } else {
            throw new Error("oauth_token is undefined!");
          }
        })
        .catch(console.error);
    });
  };

  const fetchPredictions = async () => {
    const accountTypes = {
      "cyborg": "Cyborg",
      "political_bot": "Political Bot",
      "human": "Human",
      "self_declared_bot": "Self Declared Bot",
      "social_bot": "Social Bot",
      "spam_bot": "Spam Bot",
      "other_bot": "Other Bot"
    }

    await setReasons(null);
    await setFeatures(null);
    
    const respData = await request.get(
      `/predict/${
        username.startsWith("@") ? username.slice(1) : username
      }?${queryString.stringify(state.userInfo)}`
    );
    
    await setPredictions(respData.prediction);
    await setBotname(getMax(respData.prediction)[0]);

    if (username === "") {
      await setProgressBars([]);
    } else if (typeof respData.prediction == "string") {
      await setProgressBars([]);
    } else {
      if (historyObject.length < 3) {
        await setHistoryObject((historyObject) => [...historyObject, {username: username.startsWith("@") ? username.slice(1) : username, image:respData.image, botname: accountTypes[getMax(respData.prediction)[0]], prediction: respData.prediction}])
      } else {
        await setHistoryObject(historyObject.slice(1));
        await setHistoryObject((historyObject) => [...historyObject, {username: username.startsWith("@") ? username.slice(1) : username, image:respData.image, botname: accountTypes[getMax(respData.prediction)[0]], prediction: respData.prediction}])
      }

      await setProgressBars([
        {name: "Cyborg", progress: Math.round(respData.prediction.cyborg * 100), image: cyborgImg},
        {name: "Human", progress: Math.round(respData.prediction.human * 100), image: humanImg},
        {name: "Political Bot", progress: Math.round(respData.prediction.political_bot * 100), image: politicalBotImg},
        {name: "Self Declared Bot", progress: Math.round(respData.prediction.self_declared_bot * 100), image: selfDeclaredBotImg},
        {name: "Social Bot", progress: Math.round(respData.prediction.social_bot * 100), image: socialBotImg},
        {name: "Spam Bot", progress: Math.round(respData.prediction.spam_bot * 100), image: spamBotImg},
      ]);
    }
  };

  const fetchExplanations = async () => {
    const respData = await request.get(
      `/explain_in_details/${
        username.startsWith("@") ? username.slice(1) : username
      }?${queryString.stringify(state.userInfo)}`
    );
    await setReasons(respData);
  };

  const fetchFeatures = async () => {
    const respData = await request.get(`/explainAllFeatures`);
    await setFeatures(respData);
  };

  const getMax = (object) => {
    return Object.keys(object).filter((x) => {
      return object[x] === Math.max.apply(null, Object.values(object));
    });
  };

  const downloadJSON = () => {
    let tempPredictions = {
      "username": username,
      "cyborg": `${Math.round(predictions.cyborg * 100)}%`,
      "political_bot": `${Math.round(predictions.political_bot * 100)}%`,
      "human": `${Math.round(predictions.human * 100)}%`,
      "self_declared_bot": `${Math.round(predictions.self_declared_bot * 100)}%`,
      "social_bot": `${Math.round(predictions.social_bot * 100)}%`,
      "spam_bot": `${Math.round(predictions.spam_bot * 100)}%`
    }

    const data = JSON.stringify(tempPredictions, null, 4);  
    const blob = new Blob([data], { type: "text/json" });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = `Bot-Detective-${new Date().getTime()}.json`;
    link.click();
  }

  // input & form submission handlers & validation
  const usernameIsValid = username.trim() !== "";
  const usernameIsInvalid = !usernameIsValid && usernameTouched;

  const usernameInputChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const usernameInputBlurHandler = (event) => {
    setUsernameTouched(true);
  };

  const scrollToPos = () => {
    scroller.scrollTo("more-info", {
      duration: 500,
      smooth: true,
    });
  };
  
  const scrollToRes = () => {
    scroller.scrollTo("prediction-info", {
      duration: 500,
      smooth: true,
    });
  };

  const scrollToDetailed = () => {
    scroller.scrollTo("detailed-info", {
      duration: 500,
      smooth: true,
    });
  };

  const formSubmissionHandler = async (event) => {
    event.preventDefault();
    setUsernameTouched(true);

    if (!usernameIsValid) {
      return;
    } else {
      await fetchPredictions();
      scrollToRes();
    }
  };

  const usernameInputClasses = usernameIsInvalid
    ? "prediction-input invalid"
    : "prediction-input";

  return (
    <React.Fragment>
      <div className="App">
        {request.loading &&
          ReactDOM.createPortal(
            <Loader />,
            document.getElementById("overlays")
          )}
        <AppHeader />
        <section id="mainpage">
          <Form
            onSubmit={formSubmissionHandler}
            className="text-center d-flex flex-column"
          >
            <Container fluid id="header">
              <Container id="header-title">
                <Row>
                  <Col xs={12} lg={4} xl={6}>
                    
                    <h1 className="display-font">Check if a Twitter account is a bot!</h1>
                    {width < 990 && (
                      <FaArrowDown className="mobile-arrow" />
                    )}
                  </Col>
                </Row>
              </Container>
              <Row id="more" className="justify-content-end">
                <h2 className="rotate">
                  <Alert.Link onClick={scrollToPos}>
                    <FaArrowLeft /> Learn more
                  </Alert.Link>
                </h2>
              </Row>
            </Container>
            <Container className="py-5 text-center">
              <span name="more-info" id="more_info"></span>
              <h1 className="display-font">Bot Detective V2</h1>
            </Container>
            <CardGroup>
              <Container>
                <Row className="justify-content-center pt-2 pb-5 m-auto">
                  <Col md={12} lg={6} xl={4} className="px-2 pb-3">
                    <Card className="home-cards">
                      <h4>
                        <FaQuestion />
                      </h4>
                      <Card.Body>
                        <hr />
                        <Card.Title>
                          <h5>What is Bot Detective</h5>
                        </Card.Title>
                        <Card.Text>
                          Bot Detective V2 is the most recent update to the
                          already existing{" "}
                          <a
                            target="_blank"
                            href="https://bot-detective.csd.auth.gr" rel="noreferrer"
                          >web app</a>.
                        </Card.Text>
                        <Card.Text>
                          The updated version of Bot Detective features a new
                          ensemble machine learning model that has been trained
                          to truly available up-to-date labelled data.
                        </Card.Text>
                        <Card.Text>
                          Bot-Detective V2 checks the activity of a Twitter
                          account and assigns a percentage that refers to the
                          probability of an account being human or a specific
                          type of bot.
                        </Card.Text>
                        <Card.Text>
                          The highest probability percentage corresponds to the
                          most prevalent type of bot (or human).
                        </Card.Text>
                        <Card.Text>
                          Explainable hints are detailed per Twitter account,
                          with emphasis on the ten most relevant and important
                          (still under development)
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12} lg={6} xl={4} className="px-2 pb-3">
                    <Card className="home-cards">
                      <h4>
                        <FaInfo />
                      </h4>
                      <Card.Body>
                        <hr />
                        <Card.Title>
                          <h5>Extra info</h5>
                        </Card.Title>
                        <Card.Text>
                          Use of this service requires Twitter authentication
                          and permissions. (
                          <Alert.Link href="/faq#q4">Why?</Alert.Link>)
                        </Card.Text>
                        <Card.Text>
                          If something is not working or you have questions,
                          please contact us only after reading the{" "}
                          <Alert.Link href="/faq">FAQ</Alert.Link>.
                        </Card.Text>
                        <Card.Text>
                          This service is based on the model and architecture
                          that is described in our soon-to-be published paper{" "}
                          <i>"Social Botomics: a systematic ensemble ML approach
                            for explainable and multi-class bot detection"</i>.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12} lg={6} xl={4} className="px-2 pb-3">
                    <Card className="home-cards">
                      <h4>
                        <FaUsers />
                      </h4>
                      <Card.Body>
                        <hr />
                        <Card.Title>
                          <h5>Creation Team</h5>
                        </Card.Title>
                        <Card.Text>
                          Bot Detective is an ongoing project of the{" "}
                          <Alert.Link
                            href="https://datalab.csd.auth.gr/"
                            target="_blank"
                          >
                            Datalab
                          </Alert.Link>{" "}
                          at Aristotle University of Thessaloniki, Greece.
                        </Card.Text>
                        <Card.Text>
                          <strong>Team members</strong>
                          <br />
                          <span>Maria Kouvela</span>
                          <br />
                          <span>Ilias Dimitriadis</span>
                          <br />
                          <span>Athena Vakali</span>
                          <br />
                          <span>Stelios Karamanidis</span>
                          <br />
                          <span>Anastasios Meletlidis</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </CardGroup>

            {/* Form to check user account */}
            {state.userInfo ? (
              <>
                <Container className="pt-2 pb-4">
                  <h3 className="display-font text-center">
                    Check if a Twitter account is a bot
                  </h3>
                  <span>*If you want to analyze multiple accounts <a href="/multiple">click here</a></span>
                </Container>
                <Row className="justify-content-center">
                  <Col xs={10} md={6} lg={5}>
                    <div id="check">
                      <Form.Group>
                        <InputGroup size="lg" className="prediction-group">
                          <Form.Control
                            name="username"
                            className={usernameInputClasses}
                            type="text"
                            placeholder="@username"
                            value={username}
                            onChange={usernameInputChangeHandler}
                            onBlur={usernameInputBlurHandler}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              size="lg"
                              type="submit"
                              className="prediction-btn"
                            >
                              Check Account&nbsp;&nbsp;
                              <FaSearch />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="justify-content-center text-center pb-5">
                <Col xs={10} md={6} lg={5}>
                  <Button
                    onClick={loginWithTwitter}
                    size="lg"
                    className="home-btn"
                  >
                    <FaTwitter /> Login with Twitter
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          
          {usernameIsInvalid && (
            <h4 className="text-center error-input">
              Username must not be empty.
            </h4>
          )}

          {predictions &&
            typeof predictions == "string" &&
            !request.loading &&
            username.trim() !== "" && (
              <div>
                <h4 className="text-center error-input">
                  {predictions.charAt(0).toUpperCase() + predictions.slice(1)}
                </h4>
              </div>
          )}

          <Container className="mb-5">
            {historyObject.length > 0 && !request.loading && (
              <Row className="section-header mb-4 my-3">
                <Col>
                  <h3 className="text-center">Search History</h3>
                </Col>
              </Row>
            )}
            <Row className="text-center">
              {[...historyObject].reverse().map((value, index) => {
                if (!request.loading) {
                  return (
                      <Col md={6} lg={4} key={index} className="p-1">
                        <div className="history-card">
                          <Row>
                            <Col className="history-img-col pr-0">
                              <Image src={value.image} roundedCircle/>
                            </Col>
                            <Col>
                              <h4>{value.username}</h4>
                              <h6>{value.botname}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>Spam Bot&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.spam_bot*100)}%&nbsp;</span></Col>
                            <Col>Cyborg&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.cyborg*100)}%&nbsp;</span></Col>
                          </Row>
                          <Row>
                            <Col>Self Declared Bot&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.self_declared_bot*100)}%&nbsp;</span></Col>
                            <Col>Social Bot&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.social_bot*100)}%&nbsp;</span></Col>
                          </Row>
                          <Row>
                            <Col>Human&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.human*100)}%&nbsp;</span></Col>
                            <Col>Political Bot&nbsp;<span className="history-span">&nbsp;{Math.round(value.prediction.political_bot*100)}%&nbsp;</span></Col>
                          </Row>
                          {/* <Row className="mt-2">
                            <Button
                              onClick={async () => {
                                await setUsername(value.username);
                                await fetchPredictions();
                              }} 
                              className="history-btn" 
                              sm="true"
                            >Search Again</Button>
                          </Row> */}
                        </div>
                      </Col>
                  )
                } else {
                  return null
                } 
              })}
            </Row>
          </Container>

          <span name="prediction-info" id="prediction-info"></span>

          {predictions && typeof predictions == "object" && !request.loading && (
            <div>
              <Container>
                <Row className="section-header my-3">
                  <Col>
                    <h3 className="text-center">Results</h3>
                  </Col>
                </Row>
                {(() => {
                  switch (getMax(predictions)[0]) {
                    case "cyborg":
                      return (
                        <Row className="results-card">
                          <Col md={4}>
                            <img
                              className="results-img w-50"
                              src={cyborgImg}
                              alt="cyborg"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is most likely a cyborg
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Cyborgs include celebrities, news agencies and organizations.</h4>
                          </Col>
                        </Row>
                      );

                    case "human":
                      return (
                        <Row className="results-card">
                          <Col md={4} className="text-center">
                            <img
                              className="results-img w-50"
                              src={humanImg}
                              alt="human"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is most likely a human
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Genuine human accounts.</h4>
                          </Col>
                        </Row>
                      );

                    case "political_bot":
                      return (
                        <Row className="results-card">
                          <Col md={4}>
                            <img
                              className="results-img w-50"
                              src={politicalBotImg}
                              alt="political bot"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is a most likely political bot
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Political bots are a rather unique class, including accounts that have been used for political purposes.</h4>
                          </Col>
                        </Row>
                      );

                    case "self_declared_bot":
                      return (
                        <Row className="results-card">
                          <Col md={4}>
                            <img
                              className="results-img w-50"
                              src={selfDeclaredBotImg}
                              alt="self-declared bot"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is most likely a self declared bot
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Self-declared bots refer to accounts that identify themselves as bots.</h4>
                          </Col>
                        </Row>
                      );

                    case "social_bot":
                      return (
                        <Row className="results-card">
                          <Col md={4}>
                            <img
                              className="results-img w-50"
                              src={socialBotImg}
                              alt="social bot"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is most likely a social bot
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Social bots are related to impersonators, influence bots and paybots.</h4>
                          </Col>
                        </Row>
                      );

                    case "spam_bot":
                      return (
                        <Row className="results-card">
                          <Col md={4}>
                            <img
                              className="results-img w-50"
                              src={spamBotImg}
                              alt="spam bot"
                            />
                          </Col>
                          <Col md={8} className="align-items-center">
                            <h2 className="results-header display-font text-center text-md-left text-lg-left text-xl-left">
                              This account is most likely a spam bot
                            </h2>
                            <h4 className="text-center text-md-left text-lg-left text-xl-left">Spam bots encapsulate every type of bot that is related to continuously posting spam content.</h4>
                          </Col>
                        </Row>
                      );

                    default:
                      return;

                  }
                })()}
                <br />
                <Row className="section-header my-3">
                  <Col>
                    <h3 className="text-center">Account similarity with different account types</h3>
                  </Col>
                </Row>
                <ProgressCircles list={progressBars} />
              </Container>

              <Container className="py-0 py-md-5">
                <Row className="text-center px-5 row-cols-5">
                  <OverlayTrigger
                    key="over-btn-profile"
                    placement="bottom"
                    overlay={
                        <Tooltip id="over-btn-profile">
                            <span>Check user's profile</span>
                        </Tooltip>
                    }
                  >
                    <Col md={2} xs={6} className="offset-md-1 w-100 py-3 py-md-0">
                      <a
                        rel={"noreferrer"}
                        href={`https://twitter.com/intent/user?screen_name=${
                          username.startsWith("@") ? username.slice(1) : username
                        }`}
                        target="_blank"
                      >
                        <Button className="results-btn" size="lg" variant="info">
                          <FaUserAlt /> Profile
                        </Button>
                      </a>
                    </Col>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="over-btn-details"
                    placement="bottom"
                    overlay={
                        <Tooltip id="over-btn-details">
                            <span>Check this account's activity</span>
                        </Tooltip>
                    }
                  >
                    <Col md={2} xs={6} className="py-3 py-md-0">
                      <a
                        rel={"noreferrer"}
                        href={`/details/${
                          username.startsWith("@") ? username.slice(1) : username
                        }`}
                        target="_blank"
                      >
                        <Button className="results-btn" size="lg" variant="info">
                          <FaChartBar /> Details
                        </Button>
                      </a>
                    </Col>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="over-btn-feedback"
                    placement="bottom"
                    overlay={
                        <Tooltip id="over-btn-feedback">
                            <span>Help us get better</span>
                        </Tooltip>
                    }
                  >
                    <Col md={2} xs={6} className="py-3 py-md-0">
                      <Button
                        className="results-btn"
                        size="lg"
                        variant="info" onClick={handleShow}
                      >
                        <FaPen /> Feedback
                      </Button>
                    </Col>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="over-btn-tweet"
                    placement="bottom"
                    overlay={
                        <Tooltip id="over-btn-tweet">
                            <span>Tweet results</span>
                        </Tooltip>
                    }
                  >
                    <Col md={2} xs={6} className="py-3 py-md-0">
                      <a
                        rel={"noreferrer"}
                        href={`https://twitter.com/intent/tweet?text=I just checked if @${
                          username.startsWith("@") ? username.slice(1) : username
                        } is a bot. Check it yourself at http://bot-detectivev2.csd.auth.gr!`}
                        target="_blank"
                      >
                        <Button className="results-btn" size="lg" variant="info">
                          <FaTwitter /> Tweet
                        </Button>
                      </a>
                    </Col>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="over-btn-tweet2"
                    placement="bottom"
                    overlay={
                        <Tooltip id="over-btn-tweet">
                            <span>Export predictions as JSON</span>
                        </Tooltip>
                    }
                  >
                    <Col md={2} xs={12} className="py-3 py-md-0">
                      <Button className="results-btn" size="lg" variant="info" onClick={downloadJSON}>
                        <FaDownload /> Export
                      </Button>
                    </Col>
                  </OverlayTrigger>
                </Row>
              </Container>
              <FeedbackModal show={show} setShow={setShow} username={username} botname={botname} />             
            </div>
          )}

          {predictions &&
            typeof predictions != "string" &&
            !reasons &&
            !request.loading && (
              <Container>
                <hr></hr>
                <Row className="mb-5 mt-5 text-center">
                  <Col md={12}>
                    <Form
                      onSubmit={async (e) => {
                        e.preventDefault();
                        await fetchFeatures();
                        await fetchExplanations();
                        scrollToDetailed();
                      }}
                    >
                      <Button type="submit" className="results-toggle-button">
                        <FaQuestion />
                        &nbsp;&nbsp;Load Detailed Analysis
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            )}
          <span name="detailed-info"></span>
          {reasons && typeof reasons == "object" && !request.loading && (
            <Container className="mb-5 text-center text-md-left text-lg-left text-xl-left">
              <Row className="section-header my-3">
                <Col>
                  <h3 className="text-center">Detailed Analysis</h3>
                </Col>
              </Row>
              <h2 className="mt-4 mb-3 display-font">Explanations</h2>
              <ButtonGroup className="mb-4">
                <Button className={explanationType === "basic" ? "explanation-type-button-active explanation-type-button-left" : "explanation-type-button-inactive explanation-type-button-left"} onClick={() => setExplanationType("basic")}>Basic</Button>
                <Button className={explanationType === "advanced" ? "explanation-type-button-active explanation-type-button-right" : "explanation-type-button-inactive explanation-type-button-right"} onClick={() => setExplanationType("advanced")}>Advanced</Button>
              </ButtonGroup>
              
              {explanationType === "basic" ? (
                <div>
                  <CategoryCards categories={reasons.categories} setExplanationType={setExplanationType} setBasicCat={setBasicCat} setActiveCategory={setActiveCategory} />
                </div>
              ) : (
                <div>
                  <CategoryTabs featureCategories={[reasons.categories, reasons.feature_categories, features]} setActiveCategory={setActiveCategory} activeCategory={activeCategory} basicCat={basicCat} />
                  <hr />
                  <PredictionList predictions={[reasons.feature_weights, reasons.intercept, reasons.local_pred, reasons.model_prediction]} />
                </div>
              )}

            </Container>
          )}
        </section>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
