import React from "react";
import { Switch, Route } from "react-router-dom";

import Auth from "./services/Auth";
import { Home, Faq, Publications, Datasets, Details, Multiple } from "./views";

function App() {	
	return (
		<>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/faq" component={Faq} />
				<Route path="/publications" component={Publications} />
				<Route path="/datasets" component={Datasets} />
				<Route path="/multiple" component={Multiple} />
				<Route path="/auth" component={Auth} />
				<Route path="/details/:username" component={Details} />
			</Switch>
		</>
	);
}

export default App;
