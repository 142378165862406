import React from 'react';
import useFetch from "use-http";
import ReCAPTCHA from "react-google-recaptcha"

import Header from '../components/Header/Header';
import AppHeader from '../components/AppHeader/AppHeader';
import Footer from "../components/Footer/Footer";
import { Container, Row, Col, Form, Button, Spinner, Modal } from "react-bootstrap";

const Multiple = () => {
	const [request, response] = useFetch(process.env.REACT_APP_SERVER_URL, { 
        timeout: 600000,
        cachePolicy: "no-cache"
    });

	const reRef = React.useRef();

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);

    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [file, setFile] = React.useState({});

    const [modalText, setModalText] = React.useState("");

    const inputChangeHandler = (e) => {
        setEmail(e.target.value);
    }

    const nameChangeHandler = (e) => {
        setName(e.target.value);
    }

    const fileChangeHandler = (e) => {
        setFile(e.target.files[0]);
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        const token = await reRef.current.executeAsync();

        const formData = new FormData();

        formData.append("email", email);
        formData.append("name", name);
		formData.append("file", file);
        formData.append("token", token);

        request.post("/predict_multiple", formData).then(async respData => {
            if (response.ok) {
                await setModalText(`Thanks for using bot-detective, your analysis for ${respData.count} Twitter users will be sent to your e-mail (${email}) when ready. You can leave or close this page. This will take approximately ${Math.floor((respData.count * 10) / 60)} minutes`)
                await setShow(true)
            } else {
                if (response.status !== 400) {
                    await setModalText(respData)
                    await setShow(true)   
                } else {
                    await setModalText("You left some fields empty")
                    await setShow(true)
                }
            }
        }).catch(async () => {
            await setModalText("Something went wrong")
            await setShow(true)
        });

        await setEmail("")
        await setFile({})
    }

    if (request.loading) {
		return (
			<Container className="loader zindex-tooltip">
				<Spinner animation="border" role="status">
  					<span className="sr-only">Loading...</span>
				</Spinner>
			</Container>
		);
	}

    return (
        <div>
            <ReCAPTCHA
				sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
				size="invisible"
				ref={reRef}
			/>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>{modalText}</Modal.Body>
            </Modal>

            <AppHeader />
            <Header name="Analyze multiple accounts" />
            <Container className="form-container mt-5 mb-5">
                <Form
                    onSubmit={submitHandler}
                    className="d-flex flex-column"
                >
                    <Row>
                        <Col>
                            <h3>Bot-detective will analyze your accounts and notify you by e-mail when the results are ready.</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 className="mt-3 mb-2">Upload a text (.txt) file containing all the accounts you want to analyze seperated by comma (,)</h4>
                            <h5><i>e.g. user1,user2,user3</i></h5>
                            <span>For performance reasons bot-detective can analyze a maximum of 10 accounts, files with more than 10 fields won't be accepted</span>
                            <Form.Group controlId="formFile" className="mt-2 mb-3">
                                <Form.Control className="form-control round" type="file" onChange={fileChangeHandler} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={6} xl={12}>
                            <h4>Type in your full name:</h4>
                            <Form.Control className="round" type="text" onChange={nameChangeHandler} />
                        </Col>
                        <Col md={6} lg={6} xl={12}>
                            <h4>Type in your e-mail for receiving the results:</h4>
                            <Form.Control className="round" type="email" onChange={inputChangeHandler} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="mt-3 w-25 btn-lg home-btn" variant="primary" type="submit">Start</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Footer />
        </div>
    );
}

export default Multiple;