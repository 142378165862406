import React from "react";
import "./CategoryCards.css";
import { Row, Col, Button } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaRegListAlt } from "react-icons/fa";

const CategoryCards = ({categories, setBasicCat, setExplanationType, setActiveCategory}) => {
    const categoryKeys = Object.keys(categories);
    const categoryValues = Object.values(categories);
    let sumOfCategories = 0;
    for (let j=0; j < categoryValues.length; j++) {
        sumOfCategories += Math.abs(categoryValues[j]);
    }

    const testList = {
        content: "Text-relevant features that capture the use of semantic elements, such as number of words, emoticons, inter-tweet similarity, etc.",
        network: "Features that are generated by the network of used hashtags (hashtag co-occurence)",
        sentiment: "Features that reflect the sentiment expressed in each tweet, such as percentage of sentiment-neutral tweets.",
        temporal: "Features which are exclusively relevant to the timestamps of tweets and retweets and the elapsed time between them in a given period",
        user: "Features that refer to the characteristics of the account, such as number of favorites, friends and followers."
    }

    const onClickHandler = (e) => {
        setBasicCat(e);
        setActiveCategory(e);
        setExplanationType("advanced");
    }

    return (
        <>
            <hr />
            <span id="green-dot"></span> These features contribute positively to identifying the user as human
            <br />
            <span id="red-dot"></span> These features push the Machine Learning model to identifying the user as bot
            <hr />

            <Row className="category-row d-flex justify-content-center">
                {categoryKeys.map((v, i) => 
                    <Col key={i} xs={12} md={6} lg={4} xl={4} className="p-3">
                        <div className={categoryValues[i] > 0 ? "category-card red-bg-card" : "category-card green-bg-card"}>
                            <Row className="align-items-center">
                                <Col xs={8} className="text-left">
                                <h3 className="category-titles">{v.charAt(0).toUpperCase() + v.slice(1)}</h3>
                                </Col>
                                <Col xs={4} className="text-right">
                                <CircularProgressbar 
                                value={Math.round((Math.abs(categoryValues[i])/sumOfCategories)*1000)/1000} 
                                maxValue={1} 
                                text={`${((Math.round((Math.abs(categoryValues[i])/sumOfCategories)*1000)/1000) * 100).toFixed(1)}%`} 
                                background
                                backgroundPadding={6}
                                styles={buildStyles({
                                    backgroundColor: "transparent",
                                    textColor: "#343a40",
                                    pathColor: categoryValues[i] > 0 ? "rgba(242,100,68,0.5)" : "rgba(4,217,196,0.5)",
                                    trailColor: categoryValues[i] > 0 ? "rgba(242,100,68,0.1)" : "rgba(4,217,196,0.1)"
                                })}
                                  className="card-progress"
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr></hr>
                                    <span>{testList[v]}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <Button
                                    variant="outline-dark"
                                    size="sm"
                                    block
                                    className="float-right round-btn"
                                    onClick={()=>onClickHandler(v)}
                                    >
                                    <FaRegListAlt /> Check Features</Button>
                                </Col>
                            </Row>
                        </div>
                        
                    </Col>
                )}
            </Row>
        </>
    )
}

export default CategoryCards;
