import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import Header from '../components/Header/Header';
import AppHeader from '../components/AppHeader/AppHeader';
import Footer from "../components/Footer/Footer";

const datasets = () => {
  return (
    <div className="App">
      <AppHeader />
      <>
        <Header name="Bot Datasets" />
        <section id="mainpage-bot_datasets">
            <Container className="pt-5 pb-2">
                <h4>bot-detective-2020</h4>
                <p>Description: Tweets and their authors annotated using <Alert.Link href="https://botometer.iuni.iu.edu/" target="_blank">Botometer</Alert.Link>.</p>
                <div className="text-center text-lg-left">
                    <a href="mailto:idimitriad@csd.auth.gr">
                    <Button className="results-toggle-button" size="lg">Request via email&nbsp;&nbsp;< FaEnvelope /></Button>
                    </a>
                    <hr />
                </div>
            </Container>
            <Container className="py-2">
                <h4>social-botomics-2021</h4>
                <p>Description: Twitter account IDs that refer to news agencies and companies, labelled as "cyborgs"</p>
                <div className="text-center text-lg-left">
                    <a href="mailto:idimitriad@csd.auth.gr">
                    <Button className="results-toggle-button" size="lg">Request via email&nbsp;&nbsp;< FaEnvelope /></Button>
                    </a>
                    <hr />
                </div>
            </Container>
        </section>
      </>
      <Footer />
    </div>
  );
}


export default datasets;